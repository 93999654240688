<template>
    <div class="step-populate-template-friends flex">
        <div class="flex-row step-populate-template-friends-container">
            <IntroducingUser :from="'ModalSalesTemplate'" :user="options.user" />

            <div class="chatroom flex flex-fill">
                <!-- <div v-if="!$store.getters.isMobile" class="help-top" v-html="$translate('POPULATE_TEMPLATE_HELP')" /> -->
                <div class="chat-body flex-fill">
                    <div class="messages">
                        <div class="message-row right m-b-16" :key="idx" v-for="(message, idx) in messages">
                            <div class="flex-row">
                                <Message class="flex-wrap" :message="message" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat-input flex-wrap">
                    <transition name="slide-up">
                        <AutoTexts
                            v-if="showAutoTexts"
                            :shortcut="shortcut"
                            @close="showAutoTexts = false"
                            @onAutoText="onAutoText"
                        />
                    </transition>
                    <transition name="slide-up">
                        <Emoji v-if="showEmoji" @onClickEmoji="onClickEmoji" @close="showEmoji = false" />
                    </transition>

                    <div class="textarea-wrapper">
                        <div class="flex-row">
                            <textarea
                                ref="chat-input-textarea"
                                class="chat-input-textarea"
                                @keydown="onKeydown"
                                @keypress.exact.enter.prevent="onEnter"
                                :placeholder="$translate('INPUT_MESSAGE')"
                                :maxlength="1024"
                                v-model="text"
                            />
                            <button
                                v-show="$store.getters.isMobile && text && text.length > 0"
                                @click="send(text)"
                                class="btn btn-send flex flex-wrap m-l-8 m-b-8"
                            >
                                <div class="center" v-html="$translate('SEND')" />
                            </button>
                        </div>
                        <div class="text-icons flex-row flex-between items-center">
                            <div>
                                <img
                                    v-if="!$store.getters.isMobile"
                                    @click="onClickIcon('emoji')"
                                    class="chat-input-function-icon"
                                    @mouseover="overEmoji = true"
                                    @mouseleave="overEmoji = false"
                                    :src="emojiSrc"
                                />
                                <img
                                    @click="onClickIcon('auto-texts')"
                                    class="chat-input-function-icon"
                                    @mouseover="overAutoTexts = true"
                                    @mouseleave="overAutoTexts = false"
                                    :src="autoTextSrc"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons bottom sales-template-buttons flex-wrap">
            <div class="flex-row">
                <button class="btn sales-template-button-reset" v-html="'템플릿 리셋하기'" @click="onClickReset" />

                <button
                    class="btn sales-template-button-save"
                    :class="{ active: messages.length !== 0 }"
                    v-html="'저장하기'"
                    @click="updateSalesTemplate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import IntroducingUser from '@/routes/chat/introducing-user/IntroducingUser'
import Message from '@/routes/chat/chatroom/message/Message'
export default {
    name: 'StepPopulateTemplate',
    props: ['options', 'confirmedUsers'],
    components: {
        IntroducingUser,
        Message,
    },
    data: () => ({
        showAutoTexts: false,
        overAutoTexts: false,
        showEmoji: false,
        overEmoji: false,
        selectedIdx: null,
        text: null,
        shortcut: null,
        messages: [],
    }),
    mounted() {
        this.$bus.$on('importPicturesToModalSpreadProfile', this.onPhoto)
        this.init()
    },
    beforeDestroy() {
        this.$bus.$off('importPicturesToModalSpreadProfile', this.onPhoto)
    },
    computed: {
        dom() {
            return this.$refs['chat-input-textarea']
        },
        numPhotoMessages() {
            return (this.messages || []).filter(m => m.mtype.includes('photo')).length || 0
        },
        numTextMessages() {
            return (
                (this.text || '').length +
                    (this.messages || [])
                        .filter(m => m.mtype === 'text')
                        .map(m => m.content)
                        .join('').length || 0
            )
        },
        payloadStatus() {
            return this.$translate('SPREAD_PROFILE_PAYLOAD_STATUS')
                .replace(/%s/, this.numPhotoMessages)
                .replace(/%s/, this.numTextMessages)
        },
        payloadSummary() {
            if (!this.confirmedUsers) return ''
            return this.$translate('MODAL_SPREAD_PROFILE_CONFIRM')
                .replace(/%s/, this.confirmedUsers[0].name)
                .replace(/%s/, this.confirmedUsers.length - 1)
                .replace(/%s/, this.$store.getters.actionPointList.action_spread_profile)
                .replace(/%s/, this.$store.getters.badges.action_point || 0)
        },
        emojiSrc() {
            if (this.showEmoji) {
                return require('@/assets/images/emoji_active.svg')
            } else if (this.overEmoji) {
                return require('@/assets/images/emoji_hover.svg')
            } else {
                return require('@/assets/images/emoji_inactive.svg')
            }
        },
        autoTextSrc() {
            if (this.showAutoTexts) {
                return require('@/assets/images/auto_texts_active.svg')
            } else if (this.overAutoTexts) {
                return require('@/assets/images/auto_texts_hover.svg')
            } else {
                return require('@/assets/images/auto_texts_inactive.svg')
            }
        },
    },
    watch: {
        text(newVal) {
            if (!newVal) {
                this.shortcut = null
                return
            }
            const splitted = (newVal || '').split('/')
            if (splitted.length > 1) {
                this.shortcut = splitted[1]
                this.showAutoTexts = true
            } else {
                setTimeout(_ => {
                    this.showAutoTexts = false
                }, 200)
            }
        },
    },
    methods: {
        async init() {
            await this.$store.dispatch('loadAutoTextsAllCategory')
            if (this.$store.getters.autoTextsAllCategory.sales_template) {
                const templates = this.$store.getters.autoTextsAllCategory.sales_template.filter(
                    t => t.title === `세일즈템플릿_${this.options.user.id}`
                )
                this.messages = templates.map(t => {
                    const mtype = t.content.split('"mtype"=>"')[1].split('", "content"=>"')[0]
                    let content = t.content.split('"mtype"=>"')[1].split('", "content"=>"')[1].split('"}')[0]
                    if (mtype === 'profile-photo') {
                        content = t.content
                            .split('"mtype"=>"')[1]
                            .split('", "content"=>"')[1]
                            .split('", "$$hideFunction"=>true}')[0]
                            .replace(/\\"/g, '"')
                    }

                    return { mtype, content }
                })
            }
        },
        onClickButton(idx) {
            this.selectedIdx = idx
            setTimeout(() => {
                this.selectedIdx = null
                if (idx === 0) {
                    return
                }
                this.$emit('onSpreadProfileConfirmed')
            }, 150)
        },
        onEnter() {
            if (!this.$store.getters.isMobile) {
                this.send(this.text)
                return
            }
            if ((this.text || '').length > 0) this.text += '\n'
        },
        onKeydown() {
            setTimeout(() => {
                this.text = this.dom.value
            })
        },
        send(text) {
            if (this.showAutoTexts) return
            this.messages.push({
                mtype: 'text',
                content: text,
            })
            this.text = ''
            this.dom.focus()
            this.$emit('onSpreadProfileMessage', this.messages)
        },
        onAutoText(autoText) {
            if (this.text) {
                if (this.text.includes('/')) {
                    this.text = this.text.replace(`/${this.shortcut}`, autoText.content)
                } else {
                    this.text += autoText.content
                }
                return
            }
            this.text = autoText.content
            if (this.dom) this.dom.focus()
        },
        onClickEmoji(value) {
            this.showEmoji = false
            if (!this.text) this.text = ''
            this.text += value
            if (this.dom) this.dom.focus()
        },
        onPhoto(photoUrls) {
            // if (this.numPhotoMessages > 0) {
            //     this.$toast.error('사진은 한 번만 보낼 수 있습니다.')
            //     return
            // }
            const content = {
                msg: '사진을 보냈습니다.',
                source_id: this.options.user.id,
                name: this.options.user.name || '',
                nickname: this.options.user.profile.nickname,
                height: this.options.user.profile.height,
                region: this.options.user.profile.region_name,
                birthday: this.options.user.profile.birthday,
                job: this.options.user.profile.job_name,
                urls: photoUrls,
            }
            this.messages.push({
                mtype: 'profile-photo',
                content: JSON.stringify(content),
                $$hideFunction: true,
            })
            this.$emit('onSpreadProfileMessage', this.messages)
            this.$bus.$emit('onPhotosSent', photoUrls)
        },
        allPhotos() {
            this.onPhoto((this.options.user.photos || []).map(photo => photo.url))
        },
        selectPhotos() {
            this.$modal.custom({
                component: 'ModalPhotosSelector',
                options: {
                    user: this.options.user,
                    from: 'ModalSpreadProfile',
                },
            })
        },
        onClickIcon(foo) {
            if (foo === 'auto-texts') {
                this.shortcut = null
                this.showAutoTexts = !this.showAutoTexts
                this.showEmoji = false
            }
            if (foo === 'emoji') {
                this.showAutoTexts = false
                this.showEmoji = !this.showEmoji
            }
        },
        async onClickReset() {
            this.messages = []

            await this.$store.dispatch('updateSalesTemplate', {
                salesMessage: null,
                userId: this.options.user.id,
            })
        },
        async updateSalesTemplate() {
            if (this.messages.length === 0) return

            await this.$store
                .dispatch('updateSalesTemplate', {
                    salesMessage: this.messages,
                    userId: this.options.user.id,
                })
                .then(async () => {
                    await this.$store.dispatch('loadAutoTextsAllCategory')
                    this.$emit('close')
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.step-populate-template-friends-container {
    max-height: 650px;
    border-bottom: 1px solid $grey-02;

    .all-photo {
        box-shadow: none !important;
    }
    ::v-deep .premium-badge {
        border-radius: 6px !important;
        border: solid 1px $yellow-premium !important;
        background-image: $yellow-premium-grad !important;

        color: $white !important;
    }
}
.chatroom {
    min-width: 400px !important;
    background: $grey-01;
    border: 1px solid $grey-02;

    .chat-body,
    .chat-body-mobile {
        .messages {
            height: 100%;
            .message-row:first-child {
                padding-top: 0px !important;
            }

            .message-text {
                max-width: 200px;
            }
        }
    }
    .text-icons {
        padding-top: 8px;
    }
}
.sales-template-buttons {
    margin-top: auto !important;
    margin-left: auto !important;
    margin-bottom: 12px !important;
    .sales-template-button-reset {
        padding: 8px 16px 8px 16px;
        font-size: 14px;
        background-color: $grey-09;
        @include f-medium;
        color: white;
    }
    .sales-template-button-save {
        padding: 8px 20px 8px 20px;
        font-size: 14px;
        background-color: $grey-02;
        @include f-medium;
        color: $grey-05;
        margin-right: 16px;

        &.active {
            background-color: $purple-primary;
            color: white;
        }
    }
}
</style>

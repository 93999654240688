<template>
    <div class="modal-sales-template flex">
        <div class="head flex-row flex-between items-center flex-wrap">
            <div class="flex-fill flex-row items-center">
                <div class="title" v-html="$translate(`SALES_TEMPLATE_TITLE`)" />
            </div>
            <i v-if="!$store.getters.isMobile" class="zmdi zmdi-close" @click="$emit('close')" />
        </div>
        <div class="body flex-row flex-fill">
            <StepPopulateTemplateFriends
                @onSpreadProfileMessage="onSpreadProfileMessage"
                :options="options"
                @close="$emit('close')"
            />
        </div>
    </div>
</template>

<script>
import StepPopulateTemplateFriends from '@/components/modals/modal-spread-profile/StepPopulateTemplateFriends'

export default {
    name: 'ModalSalesTemplate',
    components: {
        StepPopulateTemplateFriends,
    },
    props: ['options'],
    data: () => ({
        messages: null,
        showSbProfile: false,
        loading: false,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        user() {
            return this.options.user
        },
        hasPhotoMessages() {
            return (this.messages || []).filter(m => m.mtype.includes('photo')).length === 1
        },
    },
    methods: {
        onSpreadProfileMessage(messages) {
            this.messages = messages
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-sales-template {
    position: relative;
    min-height: 800px;
    overflow: hidden;

    .introducing-user,
    .friends-filter {
        box-shadow: none;
    }

    .friends-filter {
        .zmdi-close {
            display: none;
        }
    }

    .head {
        margin-bottom: 24px;
        img {
            width: 55px;
            margin-right: 8px;
        }

        .title {
            font-size: 18px;
            @include f-medium;
        }
    }

    .body {
        border-top: 1px solid $grey-02;

        margin: 0 -24px;
    }

    .step-populate-template-friends {
        .buttons .functions {
            display: none;
        }

        .btn-brd {
            box-shadow: none;
        }

        .chatroom {
            min-width: 400px !important;
            background: $grey-01;
            border: 1px solid $grey-02;

            .chat-body {
                .messages {
                    height: 100%;
                    .message-row:first-child {
                        padding-top: 0px !important;
                    }

                    .message-row:last-child {
                        padding-top: 16px;
                    }

                    .message-text {
                        max-width: 200px;
                    }
                }
            }

            .auto-texts {
                left: 0;
                right: 0;
            }

            .payload-status {
                font-size: 12px;
                color: $grey-07;
            }

            textarea {
                padding: 6px 0 0;
            }
        }

        .distance {
            display: none;
        }
    }

    .overlay {
        z-index: 3;
        background: rgba(0, 0, 0, 0.5);
    }

    .buttons.bottom {
        display: table;
        margin-top: 30px;
        margin-left: auto;

        button {
            // @extend .btn;
            @include flex-wrap;

            &:not(:first-child) {
                margin-left: 16px;
            }
        }
    }
}
</style>
